import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";

import { enquireScreen } from "enquire-js";
import check from "../../../assets/imgs/check.png";
import chLogo from "../../../assets/imgs/peru/chLogo.png";
import prLogo from "../../../assets/imgs/peru/prLogo.png";
import "./index.less";
import "./index-Pc.less";

const lenLimit = "Número de @len dígitos";
const rangeLimit = "Número de @min-@max dígitos";
const phoneLimit = "Número de @num dígitos, que comienzan con @num";

export class DeleteUser extends React.PureComponent {
  state = {
    hasSubmit: false,
    textInfo: null,
    textFocus: false,
    inputFocus: false,
    textPhone: null,
    message: null,
    hasCommit: false,
    idCard: null,
    idCardError: null,
    idCardFocus: false,
    agreeCheck: true,
    isMobile: false,
    showError: false,
    showModal: true,
    showDict: null,
    errorMsg: "ssss",
    localCountryKey: null,
    localCountrys: [
      { logo: chLogo, name: "Chile", key: "2" },
      { logo: prLogo, name: "Peru", key: "1" },
    ],
  };
  componentDidMount() {
    const stateTempJSON = localStorage.getItem("stateTemp");
    if (stateTempJSON) {
      this.setState({ ...JSON.parse(stateTempJSON) });
      setTimeout(() => {
        localStorage.removeItem("stateTemp");
      }, 500);
    }
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      });
    });
  }

  handleFocusText = (flag) => {
    this.setState({ textFocus: flag });
  };
  buscarChangeText = (e) => {
    const { value } = e.target;
    this.setState({ textInfo: value });
  };
  buscarChangePhone = (e) => {
    const { value } = e.target;
    const textPhone = value.replace(/[^0-9-]/g, "");
    // this.props.form.setFieldsValue({phone: textPhone})
    let errorMsg = null;
    const phonePattern = /^9\d{8}$/;
    errorMsg = !phonePattern.test(textPhone)
      ? phoneLimit.replace(/@num/g, 9)
      : null;

    this.setState({ textPhone, message: errorMsg });
  };
  buscarChangeIdCard = (event) => {
    let inputValue = event.target.value;
    let value = null;

    const min = 7;
    const max = 9;

    let numberPattern = "^([0-9]{7, 9})|([0-9]{6,8}[kK]{1})$";
    if (inputValue) {
      let length = inputValue.length;
      console.log("len", length, max);
      value = inputValue;

      if (max && length <= max) {
        if (numberPattern) {
          let check = new RegExp(numberPattern, "i");
          if (!check.test(String(inputValue))) {
            let checkValue = String(inputValue).toUpperCase();
            const endWord = "K";
            //截断endWord后面的字符
            if (endWord && checkValue.indexOf(`${endWord}`) > -1) {
              const checkValues = checkValue.split(`${endWord}`);
              value =
                checkValues[0].replace(/[^0-9.]/g, "").replace(/[-+e]/g, "") +
                endWord; //追加endWord
            } else {
              value = checkValue.replace(/[^0-9.]/g, "").replace(/[-+e]/g, "");
            }
          }
        } else {
          value = inputValue.replace(/[^0-9.]/g, "").replace(/[-+e]/g, "");
        }
      } else {
        value = inputValue.replace(/[^0-9.]/g, "").replace(/[-+e]/g, "");
      }
    }

    const idCard = value;
    // this.props.form.setFieldsValue({idCard})
    let errorMsg = null;
    let vLen = String(idCard || "").trim().length;
    if (vLen > 0) {
      if (min && max) {
        if (min > vLen || max < vLen) {
          errorMsg = rangeLimit
            .replace(/@max/g, max + "")
            .replace(/@min/g, min + "");
        }
      } else {
        if (min && min > vLen) {
          errorMsg = lenLimit + " >= " + min;
        }
        if (max && max < vLen) {
          errorMsg = lenLimit + " <= " + max;
        }
      }
    }
    this.setState({ idCardError: errorMsg });
    this.setState({ idCard });
  };
  handleSumit = async () => {
    const {
      textPhone,
      textInfo,
      idCard,
      message,
      idCardError,
      agreeCheck,
      hasCommit,
    } = this.state;
    if (hasCommit || message || idCardError || !agreeCheck) {
      return;
    }
    if (textPhone) {
      this.setState({ showError: true, errorMsg: null });
      let payload = {
        idCardType: 1,
        idCard,
        reason: textInfo,
        phone: textPhone,
        appId: 4,
      };
      const res = await this.getData(payload);
      if (res && res.ret === 0) {
        this.setState({});
        this.setState({ hasCommit: false, showError: false, hasSubmit: true });
      } else if (res && res.ret !== 0) {
        this.setState({ errorMsg: res.msg, showError: true });
        // notification.error({
        //   message: res.msg,
        //   duration: 3,
        // })
      }
    }
  };
  handleOK = () => {
    this.setState({ showError: false, errorMsg: null });
  };
  checkStatus = (response) => {
    const codeMessage = {
      200: "200",
      201: "201",
      202: "202",
      204: "204",
      400: "400",
      401: "401",
      403: "403",
      404: "404",
      406: "406",
      410: "410",
      422: "422",
      500: "500",
      502: "502",
      503: "503",
      504: "504",
    };
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    const errortext = codeMessage[response.status] || response.statusText;
    const error = new Error(errortext);
    error.name = response.status;
    error.response = response;
    throw error;
  };
  getData = (payload) => {
    const res = fetch(window.location.origin + "/desvincularCL", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json;charset=UTF-8",
      },
      mode: "cors",
      body: JSON.stringify(payload),
    })
      .then(this.checkStatus)
      .then((res) => {
        return res.json();
      })
      .catch((e, res) => {
        const status = e.name;
        let ret = status;
        let msg = "Falla del Sistema";

        if (status === 401) {
          msg = "Suspendido";
          return { ret, msg };
        }

        // environment should not be used
        if (status === 403) {
          msg = "Acceso Denegado";
        }
        if (status <= 504 && status >= 500) {
          msg = "Error de Sistema";
        }
        if (status >= 404 && status < 422) {
          msg = "No encontrado";
        }
        return { ret, msg };
      });
    return res;
  };

  buscarAgreeCheck = () => {
    let agreeCheck = this.state.agreeCheck;
    agreeCheck = !agreeCheck;
    this.setState({
      agreeCheck,
    });
  };
  handleSelectChange = (value) => {
    if (value === "2") {
      this.setState({ localCountryKey: "2" });
    } else {
      window.location.href = "/dcdesvincularPr";
    }
  };
  render() {
    const {
      hasSubmit,
      localCountrys,
      localCountryKey,
      showError,
      errorMsg,
      agreeCheck,
      isMobile,
      idCardError,
      textPhone,
      inputFocus,
      idCardFocus,
      textFocus,
      message,
      idCard,
    } = this.state;

    const agsTitle = "Solicitud de eliminación de cuenta";
    const agsTop = "Solicitud";
    const agsAreaCode = "+56";
    // const agsPhoneTitle = "*Celular(PideCash)";
    const agsPhone = "9********(PideCash)";
    const asgCard = "Número de RUN/RUT(Enviado en PideCash)";
    const asgMsg = "Motivo para eliminar esta cuenta";
    const asgAgree = "Aceptar los";
    const Privacidad = "<Privacidad>";
    const Servicio = "<Términos>";
    const agreeAnd = " y ";
    const submit = "SOLICITUD";
    const okText = "CONFIRMAR";
    const loadingText = "Cargando...";
    const result = "Su solicitud de eliminación ha sido enviada con éxito.";
    const renderDict = () => {
      return (
        <div className={"select"}>
          <div className="countrySelect">
            {localCountrys.map((item) => (
              <div
                className={[
                  "localCountry",
                  item.key === localCountryKey && "seletOn",
                ].join(" ")}
                onClick={() => this.handleSelectChange(item.key)}
              >
                <img
                  src={item.logo}
                  className="countrylogo"
                  alt="localCountry"
                />
                <span className="countryName">{item.name}</span>
              </div>
            ))}
          </div>
        </div>
      );
    };
    return (
      <>
        {!!localCountryKey && localCountryKey === "2" && (
          <div
            className={[
              "deleteWrapper" + (isMobile ? "-mob" : ""),
              (idCardFocus || textFocus) &&
                isMobile &&
                "edit" + (isMobile ? "-mob" : ""),
              hasSubmit && "deleteWrapperColor" + (isMobile ? "-mob" : ""),
            ].join(" ")}
          >
            <div className={"tabNav"}>
              <div className={"title"}> {agsTitle}</div>
            </div>
            {!hasSubmit ? (
              <>
                <div className={"content"}>
                  <div className={"fbtInfo"}>
                    <div className={"tip"}>{agsTop}</div>

                    {/* <div className={"fbtTitle"}>{agsPhoneTitle}</div> */}
                    <div
                      className={[
                        "fbtItem",
                        "fbtPhone",
                        "fbtTop",
                        inputFocus && "fbtContentOn",
                        !!message && "fbtContentError",
                      ].join(" ")}
                    >
                      <div className={"fpInfo"}>
                        <div className={"fpLabel"}>{agsAreaCode}</div>
                        <input
                          className={"fpInput"}
                          onChange={(value) => this.buscarChangePhone(value)}
                          onKeyUp={(event) => this.buscarChangePhone(event)}
                          placeholder={agsPhone}
                          onFocus={() => {
                            this.setState({ inputFocus: true });
                          }}
                          onBlur={() => {
                            this.setState({ inputFocus: false });
                          }}
                          type={"tel"}
                          maxLength={9}
                        />
                      </div>
                    </div>
                    {!!message && (
                      <div className={["showTips"].join(" ")}>
                        <span>{message}</span>
                      </div>
                    )}

                    {/* <div className={"fbtTitle"}>{asgCard}</div> */}

                    <div
                      className={[
                        "fbtItem",
                        "fbtPhone",
                        idCardFocus && "fbtContentOn",
                        !!idCardError && "fbtContentError",
                      ].join(" ")}
                    >
                      <div className={"fpInfo"}>
                        <input
                          className={["fbtItem", "fpInput", "fpIdCard"].join(
                            " "
                          )}
                          onKeyUp={(event) => this.buscarChangeIdCard(event)}
                          placeholder={asgCard}
                          onFocus={() => {
                            this.setState({ idCardFocus: true });
                          }}
                          onBlur={() => {
                            this.setState({ idCardFocus: false });
                          }}
                          value={idCard}
                          maxLength={9}
                          minLength={7}
                          onChange={(currentValue) => {
                            this.buscarChangeIdCard(currentValue);
                          }}
                        />
                      </div>
                    </div>
                    {!!idCardError && (
                      <div className={["showTips"].join(" ")}>
                        <span>{idCardError}</span>
                      </div>
                    )}
                    {/* <div className={"fbtTitle"}>{asgMsg}</div> */}

                    <div
                      className={[
                        "fbtItem",
                        "fbtContent",
                        textFocus ? "fpTextOn" : null,
                      ].join(" ")}
                    >
                      <textarea
                        maxLength={100}
                        onChange={(value) => this.buscarChangeText(value)}
                        className={[
                          "fpText",
                          textFocus ? "fpTextOn" : null,
                        ].join(" ")}
                        onFocus={() => this.handleFocusText(true)}
                        onBlur={() => this.handleFocusText(false)}
                        placeholder={asgMsg}
                      />
                    </div>

                    {/* agree checkbox */}
                    <Router>
                      <div className={"agreeWrapper"}>
                        <div
                          className={["laCheck", agreeCheck && "hasCheck"].join(
                            " "
                          )}
                          onClick={this.buscarAgreeCheck}
                        >
                          {agreeCheck && (
                            <img className="laImg" src={check} alt="s" />
                          )}
                        </div>
                        <div className={"laText"}>
                          <span className={"normolColor"}>{asgAgree}</span>
                          <Link to="/acuerdoDeServicio?gl=2" target="_blank">
                            <span className={"doc"}>{Servicio}</span>
                          </Link>
                          <span
                            className={"normolColor"}
                            style={{ margin: "0 3px" }}
                          >
                            {agreeAnd}
                          </span>
                          <Link to="/acuerdoDePrivacidad?gl=2" target="_blank">
                            <span className={"doc"}>{Privacidad}</span>
                          </Link>
                        </div>
                      </div>
                    </Router>
                  </div>

                  {!isMobile && (
                    <div
                      className={[
                        "fbtAction",
                        textPhone && !idCardError && agreeCheck && !message
                          ? "fbtActionOn"
                          : null,
                      ].join(" ")}
                      onClick={() => this.handleSumit()}
                    >
                      <div className={"fbtDo"}>{submit}</div>
                    </div>
                  )}
                </div>
                {isMobile && (
                  <div
                    className={[
                      "fbtAction",
                      textPhone && !idCardError && agreeCheck && !message
                        ? "fbtActionOn"
                        : null,
                    ].join(" ")}
                    onClick={() => this.handleSumit()}
                  >
                    <div className={"fbtDo"}>{submit}</div>
                  </div>
                )}
              </>
            ) : (
              <div className={"fbtSuccess"}>
                <span>{result}</span>
              </div>
            )}

            {showError && (
              <div className={["errorModal"].join(" ")}>
                <div
                  className={[
                    "errorContent",
                    !errorMsg && "loadingContent",
                  ].join(" ")}
                >
                  {errorMsg ? (
                    <div
                      className={[
                        "errorHeader",
                        isMobile && "errorHeader-mob",
                      ].join(" ")}
                    ></div>
                  ) : (
                    <div
                      className={["loader", isMobile && "loader-mob"].join(" ")}
                    />
                  )}
                  <div
                    className={["errorMsg", isMobile && "errorMsg-mob"].join(
                      " "
                    )}
                  >
                    {errorMsg || loadingText}
                  </div>
                  {errorMsg && (
                    <div
                      onClick={() => this.handleOK()}
                      className={["btn", isMobile && "btn-mob"].join(" ")}
                    >
                      {okText}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {!localCountryKey && renderDict()}
      </>
    );
  }
}

export default DeleteUser;
